// Angular Modules
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// External Modules
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';

// Models
import { AppEnvironmentConfig } from '../../models/environment-config.model';
import { ClientMarketingAutomationModel } from '../../models/client-marketing-automation.model';
import { HeaderContentModel } from '../../models/header-content.model';
import { IAuth0ClientInfo, IClientList, IUserProfileBasedOnFields } from '../../models/auth0-types.model';
import { IUserSettingsUsageReportURL } from '../../models/user-settings/user-settings-usage-report.model';
import { IUserSettingsConfiguration } from '../../models/user-settings/user-settings-config.model';

import { environment } from '../../../../environments/environment';

// Enums
import { LocalStorageEnum } from '../../enum/local-storage-keys.enum';
import { UserSettingsEnum } from '../../enum/user-settings.enum';
import { UserProfileFieldsEnum } from '../../enum/user-profile-fields.enum';

// Services
import { AccessControlService } from '../../../core/permission/service/access-control.service';
import { AuthService } from '../../../core/services/auth.service';
import { ConfigService } from '../../../core/services/config.service';
import { CryptoService } from '../../../core/services/crypto.service';
import { EditAudienceService } from '../../../edit-audience/services/edit-audience.service';
import { SessionManagerService } from '../../../core/services/session-manager.service';
import { UserSettingsService } from '../../services/user-settings/user-settings.service';


/**
 * User Settings Component.
 */
@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  /**
   * appEnvironment to get app environment config
   */
  private appEnvironment: AppEnvironmentConfig;
  /**
   * clientList to get client list
   */
  public clientList: IAuth0ClientInfo[] = [];
  /**
   * currentAppPermissions to get current app permission
   */
  public currentAppPermissions?: string | string[];
  /**
   * headerContents to get header contents
   */
  public headerContents: HeaderContentModel;
  /**
   * marketingAutomationUrl to get MA url
   */
  public marketingAutomationUrl: string = '';
  /**
   * reportCreatedDate to get download usage report created date
   */
  public reportCreatedDate: string = '';
  /**
   * reportCreatedOn to get download usage report created date
   */
  public reportCreatedOn: string = '';
  /**
   * selectedClient to get current selected client
   */
  public selectedClient?: string;
  /**
   * subscription to api calls
   */
  private subscription: Subscription = new Subscription();
  /**
   * userSettingsEndpoints to get user settings endpoints
   */
  public userSettingsEndpoints: IUserSettingsConfiguration;
  /**
   * userEmailName to get user email name
   */
  public userEmailName?: string;
  /**
   * userProfile to get user profile
   */
  private userProfile?: IUserProfileBasedOnFields;
  /**
   * userNameInitials to get user name initial
   */
  public userNameInitials?: string;
  /**
   * usageReportDownloadUrl to get download usage report url
   */
  public usageReportDownloadUrl: string;
  /**
   * isConsoleLogin to if login from console
   */
  public isConsoleLogin: boolean;
  /**
   * switchClientStatusIndicator is used for displaying the loader until client switch is complete.
   */
  public switchClientStatusIndicator = false;
  /**
   * dXeHelpEnabled is used for displaying the DXE help desk URL until sunset
   */
  public dXeHelpEnabled = false;

  /**
   * legacyHelpDesk is used to display legacy help Center and DXE Help Center, only for internal users 4 days before sunset
   */
  public legacyHelpDesk = false;

  public userDomain = 'https://auth0.exp-dev.mercuryhealthcare.com';

  /**
   * The constructor method.
   * @param datepipe is used to convert date in date format
   * @param ngxService permission service
   * @param configService configuration service
   * @param cryptoService crypto service
   * @param errorHandlerService is used to handle error
   * @param userSettingsService used for user setting services
   * @param auth for auth services
   * @param accessControlService for permission services
   * @param router used for routing
   */
  constructor(
    public datepipe: DatePipe,
    public ngxService: NgxPermissionsService,
    private readonly editAudienceService: EditAudienceService,
    private readonly auth: AuthService,
    private readonly accessControlService: AccessControlService,
    private readonly configService: ConfigService,
    private readonly cryptoService: CryptoService,
    private readonly userSettingsService: UserSettingsService,
    private readonly sessionManager:SessionManagerService,
    private readonly router: Router,
    @Inject('Window') private window: Window,
  ) {
    // Load the header static contents from app-contents.json file
    this.headerContents = this.configService.getContents('header');
    this.userSettingsEndpoints = this.configService.userSettingsEndpoints;
    this.usageReportDownloadUrl = '';
    this.appEnvironment = environment;
    this.isConsoleLogin = false;
    this.userDomain = localStorage.getItem(LocalStorageEnum.AUTH0_DOMAIN) as string;
  }

  /**
   * The ngOnIt method.
   */
  public ngOnInit(): void {
    this.userProfile = this.auth.getUserProfileBasedOnFields([UserProfileFieldsEnum.EMAIL, UserProfileFieldsEnum.HG_CUSTOMER]);
    this.userEmailName = this.userProfile?.email ? this.userProfile.email : 'NOT_AVAILABLE';
    this.getUserNameInitials();
    const auth0ConnectionName = localStorage.getItem(LocalStorageEnum.CONNECTION);
    if (auth0ConnectionName) {
      this.isConsoleLogin = environment.AUTH0.consoleConnection === this.cryptoService.decrypt(auth0ConnectionName);
    }
    this.subscription.add(this.auth.getClients().subscribe({
      next: (clients: IClientList) => {
        this.clientList = clients.items;
        if (this.clientList.length === 1) {
          this.prepareDropDownOptions();
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(`<UserSettingsComponent> - <ngOnInit> Error occurred during get clients...`, error);
        this.sessionManager.stopWatching();
        this.auth.logout();
      },
    }));
    this.getHelpCenterLinks();
  }

  /**
   * get initials of user name for profile photo
   */
  public getUserNameInitials(): void {
    if (this.userEmailName && this.userEmailName !== 'NOT_AVAILABLE') {
      this.userNameInitials = this.userEmailName.charAt(0).toUpperCase();
    }
  }

  /**
   * This method is used for logging out from Auth0.
   *
   * @memberof HeaderComponent
   */
  public logout(): void {
    this.sessionManager.stopWatching();
    this.auth.logout();
  }

  /**
   * This method is used to get usage report url
   */
  getUsageReportUrl(): void {
    this.switchClientStatusIndicator = true;
    this.subscription.add(this.userSettingsService.getUsageReportUrl().subscribe({
      next: (usageReportURL: IUserSettingsUsageReportURL) => {
        if (typeof usageReportURL === 'object' && Object.keys(usageReportURL).length > 0) {
          this.usageReportDownloadUrl = usageReportURL.url;
          if (usageReportURL.generatedReportDate) {
            let reportDate = usageReportURL.generatedReportDate.replace(/(\d+)(st|nd|rd|th)/, '$1');
            this.reportCreatedDate = (this.datepipe.transform(reportDate, UserSettingsEnum.DATE_FORMAT)) || '';
          }
        } else {
          this.usageReportDownloadUrl = '';
        }
      },
      error: (e) => {
        this.usageReportDownloadUrl = '';
        this.switchClientStatusIndicator = false;
        console.log(`<UserSettingsComponent> - <getUsageReportUrl> Error occurred during subscribing getUsageReportUrl...`, e);
      },
      complete: ()=> this.switchClientStatusIndicator = false,
    }));
  }

  /**
   * This method is used to set href download usage report url and download report
   */
  downloadUsageReport(): void {
    const link = document.createElement('a');
    link.href = this.usageReportDownloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Describes the client change call which include changing hg-client data on local storage and initializing gain insights for respective client and user
   */
  changeClient(): void {
    if (this.editAudienceService.isCriteriaUnsaved) {
      this.editAudienceService.openUnsavedAudienceModal();
      return;
    }
    this.switchClientStatusIndicator = true;
    this.marketingAutomationUrl = '';
    this.usageReportDownloadUrl = '';
    const clientDetail: IAuth0ClientInfo[] = this.clientList
      .filter((clientInfo: IAuth0ClientInfo) => clientInfo.clientName === this.selectedClient);
    this.auth.updateClientInfo(clientDetail[0]);
    this.subscription.add(this.accessControlService.definePermissions().subscribe({
      next: () => {
        this.auth.sendDataToGainSights();
        // validate the permission.
        this.validatePermAndFetchUsageReportUrl();
        this.getClientMAUrl();
        this.accessControlService.getLandingPageBasedOnUserScopes().then((landingPage: string) => {
          this.router.navigate([landingPage]).then(()=>{
            this.auth.clientNavigationInProgressSubject.next(true);
            this.auth.authenticationInProgressSubject.next(true);
            this.window.location.reload();
            this.window.scrollTo({
              top: 2,
              behavior: 'smooth',
            });
            const self = this;
            setTimeout(() => {
              const moveToCurrentPos = self.window.scrollY >= 1 ? 0 : 1;
              self.window.scrollTo(0, moveToCurrentPos);
            }, 50);
          });
          this.auth.authenticationInProgressSubject.next(false);
          this.auth.clientNavigationInProgressSubject.next(false);
        });
      }, error: (e) => {
        this.auth.authenticationInProgressSubject.next(false);
        this.auth.clientNavigationInProgressSubject.next(false);
        console.log(`<UserSettingsComponent> - <changeClient> Error occurred during subscribing definePermissions...`, e);
      },
    }));
  }

  /**
   * Get Client's Marketing Automation Preference (API Call) and generate login URL for MA.
   * TODO : SSO/SAML integration for SFMC/SFHC. Now static login URL's configured for SFMC/SFHC
   */
  getClientMAUrl(): void {
    this.ngxService.hasPermission('client-ma-preference-read').then((isPermissionValid: boolean) => {
      if (isPermissionValid) {
        this.subscription.add(this.userSettingsService.getMarketingAutomationConfig().subscribe({
          next: (response: ClientMarketingAutomationModel[]) => {
            let clientMA: string = '';
            if (response.length) {
              const clientMADetail: ClientMarketingAutomationModel[] = response.filter((ma: ClientMarketingAutomationModel) => ma.isDeleted === 0);
              if (clientMADetail.length && clientMADetail[0].externalSourceName) {
                clientMA = clientMADetail[0].externalSourceName.toUpperCase();
              }
              switch (clientMA) {
                case 'ELOQUA':
                  this.marketingAutomationUrl = this.userSettingsService.getEloquaURL();
                  break;
                case 'SFMC':
                  this.marketingAutomationUrl = this.appEnvironment.SFMC_LOGIN_URL;
                  break;
                case 'SFHC':
                  this.marketingAutomationUrl = this.appEnvironment.SFHC_LOGIN_URL;
                  break;
                default:
                  this.marketingAutomationUrl = '';
              }
            } else {
              this.marketingAutomationUrl = '';
            }
          },
          error: (e) => {
            console.log(`<UserSettingsComponent> - <getClientMAUrl> Error occurred during subscribing Client MA URL...`, e);
            this.switchClientStatusIndicator = false;
          },
          complete: () => this.switchClientStatusIndicator = false,
        }));
      } else {
        this.switchClientStatusIndicator = false;
      }
    }, () => {
      this.switchClientStatusIndicator = false;
    });
  }

  private validatePermAndFetchUsageReportUrl() {
    this.usageReportDownloadUrl = '';
    this.ngxService.hasPermission('user-settings-usage-report').then((isPermissionValid: boolean) => {
      if (isPermissionValid) {
        this.getUsageReportUrl();
      } else {
        this.usageReportDownloadUrl = '';
      }
    });
  }

  public async navigateToRoute() {
    // Below flag is required to keep track of which route is triggered when user is having unsaved audience changes
    // When click on Save Button from Audience Settings popup window (with unsaved warning message), redirecting to below route.
    let routeName: string;
    const appIntegrationsPermission = await this.ngxService.hasPermission(['app-integrations-admin']);
    if (appIntegrationsPermission) {
      routeName = this.headerContents.profileRedirectData.applicationIntegrationsRoute;
    } else {
      const fileUploadsPermissions = await this.ngxService.hasPermission(['file-config-read', 'uploads-read']);
      if (fileUploadsPermissions) {
        routeName = this.headerContents.profileRedirectData.applicationIntegrationsRouteFilesPermission;
      } else {
        routeName = this.headerContents.profileRedirectData.applicationIntegrationsRoute;
      }
    }
    this.window.scrollTo({
      top: 2,
      behavior: 'smooth',
    });
    setTimeout(() => {
      this.router.navigate([`/${routeName}`]).then(() => {
        const moveToCurrentPos = window.scrollY >= 1 ? 0 : 1;
        this.window.scrollTo(0, moveToCurrentPos);
      });
    }, 50);
  }

  initUserSettingsDropDownOptions(open:boolean) {
    if (!open || this.clientList.length === 1) return;
    this.prepareDropDownOptions();
  }

  prepareDropDownOptions() {
    const userProfile = JSON.parse(localStorage.getItem(LocalStorageEnum.USER_PROFILE) || '{}');
    if (userProfile['hg-customer'] && userProfile['hg-customer']?.clientName) {
      this.selectedClient = this.cryptoService.decrypt(userProfile['hg-customer'].clientName.toString());
    }
    this.getClientMAUrl();
    // validate the permission.
    this.validatePermAndFetchUsageReportUrl();
  }

  navigateToFeedbackPortal() {
    window.open('assets/product-feedback-portal.html');
  }

  navigateToHelpCenter() {
    this.ngxService.hasPermission('support-center-access').then((isPermissionValid: boolean) => {
      const isCustomDomain: number = +(this.userDomain === this.configService.auth0Domain);
      const queryParam = `is-sf-user=${isPermissionValid}&RelayState=/?isCustomDomain=${isCustomDomain}`;
      this.window.open(`${this.userSettingsEndpoints.dXeHelpURL}?${queryParam}`);
    });
  }

  getHelpCenterLinks() {
    const todayDate  = new Date().getTime();
    const sunsetDate = this.configService.dXeEnableDate();
    this.dXeHelpEnabled =  (sunsetDate.getTime() <  todayDate);   // if true display old else display New
    const preSunsetDate = sunsetDate.setDate(sunsetDate.getDate() - 4 );
    this.legacyHelpDesk = (this.userEmailName && this.userEmailName.indexOf('mercuryhealthcare.com') > -1) && !this.dXeHelpEnabled &&  (preSunsetDate <= todayDate) || false;
    this.userSettingsEndpoints.dXeHelpURL = `https://${this.userDomain}${this.userSettingsEndpoints.dXeHelpURL}`;
  }

  /**
   * The ngOnDestroy method to destroy subscription.
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
