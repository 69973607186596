// Core Module
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

// Helpers
import { Tree, TreeNode } from '../helpers/tree';

// Models
import { ICheckboxOptions, ICheckboxOutputData } from '../models/checkbox-tree/checkbox-tree.model';

@Component({
  selector: 'app-checkbox-tree',
  templateUrl: './checkbox-tree.component.html',
  styleUrls: ['./checkbox-tree.component.scss'],
})

export class CheckboxTreeComponent implements OnInit, OnChanges {

  @Input() idPrefix!: string;
  @Input() searchTerm: string = '';
  @Input() dataSet!: TreeNode;
  @Input() preSelectedValues!: string[];
  @Input() typeConfig!: ICheckboxOutputData;
  @Input() removedNode!: Record<string, any>;
  @Input() options!: ICheckboxOptions;

  @Output() selectedValues = new EventEmitter();
  
  // This event emitter is used for opening up niche pdf in new tab
  @Output() openInfoHandler = new EventEmitter();

  private dataSetCopy!: TreeNode;
  public topLevelLoadMore : boolean = false;
  public loadSet: number = 10;
  public treeObj = new Tree(); 
  private flatStructureData: boolean = false;

  ngOnInit(): void {
    this.flatStructureData = this.dataSet?.children?.every((child) => child.children.length === 0);
    this.topLevelLoadMore = this.dataSet?.children?.length > 10;
    if (this.topLevelLoadMore) this.loadSet = 10;
  }

  ngOnChanges(changes: SimpleChanges) {
    // Need to call on first time change
    if (changes?.dataSet?.currentValue && changes?.dataSet?.currentValue?.children?.length) {
      this.treeObj.root = this.dataSet;
      this.dataSetCopy = structuredClone(this.dataSet);
    }

    if (changes?.searchTerm?.currentValue !== changes?.searchTerm?.previousValue && !changes?.searchTerm?.firstChange) {
      this.search();
    }

    if (changes?.preSelectedValues?.currentValue?.length) {
      this.selectPreSelectedNodes();
    }

    if (changes?.removedNode?.currentValue !== changes?.removedNode?.previousValue && !changes?.removedNode?.firstChange) {
      this.deselectNode();
    }
  }

  trackByFn = (index: number): number => index;

  loadMore(nodeName: string): void {
    if (this.flatStructureData) {
      this.loadSet += 10;
      return;
    }

    if (this.topLevelLoadMore && !nodeName) {
      this.loadSet += 10;
      return;
    }

    this.treeObj.loadMore(nodeName, 10);
  }

  openInfo($event: any): void {
    this.openInfoHandler.emit($event);
  }

  emitSelectedNodes(): void {
    this.selectedValues.emit({ ...this.typeConfig, 'selected':  Array.from(this.treeObj.selectedUniqueNodes.values()) });
  }

  openCloseNodeDropdown(nodeName: string, isNodeOpen: boolean) {
    this.treeObj.expandOrCollapse(nodeName, isNodeOpen);
  }

  selectAll() {
    this.treeObj.selectAll(true);
    this.emitSelectedNodes();
  }

  openInfoLink(link: string) {
    window.open(link, '_blank');
  }

  clear() {
    this.treeObj.selectAll(false);
    this.emitSelectedNodes();
  }

  toggle(node: TreeNode) {
    const state: boolean = !node.checked;
    this.treeObj.toggle(node.key, state);
    this.emitSelectedNodes();
  }

  selectPreSelectedNodes() {
    const uniqueNodes = new Set(this.preSelectedValues);
    for (let node of uniqueNodes) {
      this.treeObj.toggle(node, true);
    }
    this.emitSelectedNodes();
  }

  deselectNode() {
    this.treeObj.toggle(this.removedNode?.code, false);
    this.emitSelectedNodes();
  }

  search() {
    this.treeObj.root = structuredClone(this.dataSetCopy);
    this.dataSet = this.treeObj.root;
    if (!this.searchTerm) {
      this.treeObj.selectedUniqueNodes.forEach(selectedItem => {
        this.treeObj.toggle(selectedItem.key, true);
      });
      return;
    }
    const isNodeOpen = true;
    const tree = this.treeObj.search(this.searchTerm, isNodeOpen);
    if (Array.isArray(tree)) this.dataSet.children = tree as TreeNode[]; 
    this.treeObj.selectedUniqueNodes.forEach(selectedItem => {
      this.treeObj.toggle(selectedItem.key, true);
    });
  }
}
