<ng-container *ngIf="dataSet?.children && dataSet?.children?.length">
  <div class="d-flex fw-bold font-size-12 py-3 d-flex border-bottom checkbox-tree-action-panel">
    <a href="javascript:void(0)" (click)="selectAll()" class="link-primary fw-bold"
      [attr.id]="idPrefix ? idPrefix+'-select-all' : 'select-all'">Select all</a>
    <a href="javascript:void(0)" (click)="clear()" class="px-3 link-primary fw-bold"
      [attr.id]="idPrefix ? idPrefix+'-clear' : 'clear'">Clear</a>
    <span *ngIf="treeObj.selectedUniqueNodes.size" class="font-size-12 ms-auto selected-count pe-3"
      [attr.id]="idPrefix ? idPrefix+'-total-selected' : 'total-selected'">{{treeObj.selectedUniqueNodes.size}} selected
    </span>
  </div>
  <ul class="list-unstyled checkbox-tree border-bottom mb-0 custom"
    [attr.id]="idPrefix ? idPrefix+'-checkbox-tree-wrapper' : 'checkbox-tree-wrapper'">
    <ng-container *ngTemplateOutlet="checkboxTreeBranch; context:{ list: dataSet?.children, showMore: loadSet }"></ng-container>
  </ul>
</ng-container>

<div class="d-flex justify-content-center" *ngIf="dataSet && !dataSet.children?.length">
  <p class="py-2 font-size-14">No Search Results</p>
</div>

<ng-template #checkboxTreeBranch let-list="list" let-totalLength="list.length"
  let-showMore="showMore">
  <ng-container *ngFor="let item of $any( list | slice: 0: showMore ); let first = first; let last=last; let index = index; trackBy:trackByFn;">
    <li>
      <div *ngIf="item?.key && item?.key_as_string" class="d-flex px-4 pb-3 checkbox-tree-branch" [ngClass]="{'border-top' : (first && !item?.children?.length || item?.children?.length>0) ,'pt-3 parent-branch': item?.children?.length>0 , 'pt-4 children-branch first-branch': first && !item?.children?.length, 'pb-4 children-branch': last && !item?.children?.length, 'branch-open':item.open, 'children-branch': (first && !item?.children?.length) || (last && !item?.children?.length)}">
        <div class="checkbox m-0 flex-grow-1 row-container" [ngClass]="{'pt-1' : first }">
          <input class="form-check-input" [(ngModel)]="item.checked" type="checkbox" [attr.id]="idPrefix ? idPrefix+'-'+index+'-'+item.id+'-checkbox' : index+'-'+item.id+'-checkbox'" (click)="toggle(item)"/>
          <label class="form-check-label text-capitalize" [ngClass]="{'partially-selected': item.checked && !item.isFullySelected}"
            [attr.for]="idPrefix ? idPrefix+'-'+index+'-'+item.id+'-checkbox' : index+'-'+item.id+'-checkbox'">{{item.key_as_string}}</label>
            <div *ngIf="item.infoPopupLink" class="float-end icon-container" (click)="openInfoLink(item.infoPopupLink)">
              <label class="infoLink cursor-pointer">Info</label>
              <i class="mh-material-icons warning-image align-text-bottom font-size-16 infoIcon">
                  <span class="visually-hidden">Open in new window</span>open_in_new
              </i>
            </div>
          <i *ngIf="item.showTooltip" role="button" class="bi bi-question-circle m-2 font-size-21 mb-1 align-middle" ngbTooltip="{{item.tooltipInfo}}"
            placement="top" container="body"></i>
        </div>
        <div class="flex-shrink-0" *ngIf="item.children?.length">
          <a href="javascript:void(0);" (click)="openCloseNodeDropdown(item.id, !item.open)" role="button" class="text-dark position-relative" [attr.id]="idPrefix ? idPrefix+'-'+index+'-'+item.id+'-arrow' : index+'-'+item.id+'-arrow'">
            <i class="text-dark font-size-20 transition-all position-absolute bi-chevron-up end-50" [ngClass]="{'rotate-180': !item.open}"><span class="visually-hidden">Arrow Down</span></i>
          </a>
        </div>
      </div>
    </li>
    <li *ngIf="last && (showMore < totalLength)">
      <a href="javascript:void(0);" [attr.id]="idPrefix ? idPrefix+'-show-more' : 'show-more'"
        class="link-show-more link-primary d-block pb-4 font-size-14 ps-2" (click)="loadMore(item.parent)">Show more</a>
    </li>
    <li>
      <ul class="list-unstyled" #collapse="ngbCollapse" [(ngbCollapse)]="!item.open">
        <ng-container
          *ngTemplateOutlet="checkboxTreeBranch; context:{ list: item.children, showMore:item.limitTo}"></ng-container>
      </ul>
    </li>
  </ng-container>
</ng-template>
