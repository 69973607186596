/* eslint-disable @typescript-eslint/comma-dangle */
import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatcher, UrlSegment } from '@angular/router';
import { WebComponentWrapper, WebComponentWrapperOptions } from '@angular-architects/module-federation-tools';

import { environment } from '../environments/environment';

import { AuthGuard } from './core/guards/auth.guard';
import { FederatedDomainResolver } from './core/guards/federated-domain-resolver.guard';
import { PermissionGuard } from './core/guards/permission.guard';
import { SessionValidationGuard } from './core/guards/session-validation.guard';
import { UnsavedAudienceDetectorGuard } from './core/guards/unsaved-audience-detector.guard';

import { MainNavigationEnum } from './pem-shared/enum/main-navigation-url.enum';
import { SubNavigationEnum } from './pem-shared/enum/sub-navigation-url.enum';
import { AuthLoginComponent } from './pem-shared/auth-login/auth-login.component';
import { ClientListComponent } from './pem-shared/client-list/client-list.component';
import { UnauthorizedComponent } from './pem-shared/unauthorized/unauthorized.component';


/**
 * This remote entry path is going to serve the exposed module
 */
const REMOTE_ENTRY_PATH: string = `${environment.pes_wrapper_bucket_name}/remoteEntry.js`;

const ROUTE_PREFIX: string = `pes`;
/**
 * This below method is used to activate the parent route
 * based on combination of parent/nested state.
 * @param prefix
 * @returns UrlSegment[]
 */
export function startsWith(prefix: string): UrlMatcher {
  return (url: UrlSegment[]) => {
    const fullUrl = url.map(u => u.path).join('/');
    if (fullUrl.startsWith(prefix)) {
      return ({ consumed: url });
    }
    return null;
  };
}

/**
  * remoteEntry: REMOTE_ENTRY_PATH (pes-web-app hosted domain),
  * remoteName: 'angularjs',
  * exposedModule: './app-module', (pes-web-app exposed module name)
  * elementName: 'pes-main-wrapper', (pes-web-app exposed main wrapper name)
 */
const routes: Routes = [
  // Login Route
  { 
    path: MainNavigationEnum.LOGIN,
    component: AuthLoginComponent,
    canActivate: [AuthGuard],
    // Before loading login component, as prerequisite Legacy Federated domains
    resolve: { data: FederatedDomainResolver }
  },
  // Client List Route
  { path: MainNavigationEnum.CLIENT_LIST, component: ClientListComponent, canActivate: [SessionValidationGuard] },
  // Backward support for audience-list route (redirection to angular route)
  // { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.AUDIENCE_INSIGHTS}`, redirectTo: `${MainNavigationEnum.AUDIENCE_INSIGHTS}`, pathMatch: 'full' },
  // Backward support for my-campaigns, all-campaigns, favorite-campaigns route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CAMPAIGN_WORKFLOW}/campaigns-list/:route`, redirectTo: `${MainNavigationEnum.CAMPAIGN_WORKFLOW}/:route` },
  // Backward support for campaigns-list route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CAMPAIGN_WORKFLOW}/campaigns-list`, redirectTo: `${MainNavigationEnum.CAMPAIGN_WORKFLOW}` },
  // Backward support for campaign overview route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}`, redirectTo: `${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.OVERVIEW}`, pathMatch: 'full' },
  // Backward support for campaign overview route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.OVERVIEW}`, redirectTo: `${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.OVERVIEW}` },
  // Backward support for campaign reporting route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.CAMPAIGN_REPORTING}`, redirectTo: `${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.CAMPAIGN_REPORTING}` },
  // Backward support for campaign edit-tactics route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.EDIT_TACTICS}`, redirectTo: `${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.EDIT_TACTICS}` },
  // Backward support for campaign cost-schedule route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.COST_SCHEDULE}`, redirectTo: `${MainNavigationEnum.CAMPAIGN_WORKFLOW}/${SubNavigationEnum.CAMPAIGN}/${SubNavigationEnum.COST_SCHEDULE}` },
  // Backward support for marketing-kpi, hospital-kpi route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CPI}/:route`, redirectTo: `${MainNavigationEnum.CPI}/:route` },
  // Backward support for cpi route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.CPI}`, redirectTo: `${MainNavigationEnum.CPI}` },
  // Backward support for application-integrations route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${SubNavigationEnum.INTEGRATIONS}`, redirectTo: `${MainNavigationEnum.APPLICATION_INTEGRATIONS}/${SubNavigationEnum.INTEGRATIONS}` },
  // Backward support for file-configurations, file-upload route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${SubNavigationEnum.INTEGRATIONS}/:route`, redirectTo: `${MainNavigationEnum.APPLICATION_INTEGRATIONS}/:route` },
  // Backward support for lookup route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.LOOKUP}`, redirectTo: `${MainNavigationEnum.LOOKUP}` },
  // Backward support for search, consumer-profile route (redirection to angular route)
  { path: `${MainNavigationEnum.LEGACY_PREFIX}/${MainNavigationEnum.LOOKUP}/:route`, redirectTo: `${MainNavigationEnum.LOOKUP}/:route` },
  {
    matcher: startsWith(ROUTE_PREFIX),
    component: WebComponentWrapper,
    data: {
      remoteEntry: REMOTE_ENTRY_PATH,
      remoteName: 'angularjs',
      exposedModule: './app-module',
      elementName: 'pes-main-wrapper',
      permissions: {
        only: ['pes-app-read'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED
      }
    } as WebComponentWrapperOptions,
    canActivate: [ SessionValidationGuard, PermissionGuard, UnsavedAudienceDetectorGuard ]
  },
  {
    path: MainNavigationEnum.MPI,
    loadChildren: () => import('./market-planner/market-planner.module').then(m => m.MarketPlannerModule),
    data:{
      permissions: {
        only: ['standard-report-viewer', 'consumer-iq-report-viewer', 'retention-iq-report-viewer'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED
      }
    },
    canActivate: [SessionValidationGuard, PermissionGuard, UnsavedAudienceDetectorGuard],
  },
  {
    path: MainNavigationEnum.AUDIENCE_INSIGHTS,
    loadChildren: () => import('./audience-insights/audience-insights.module').then(m => m.AudienceInsightsModule),
    data:{
      permissions: {
        only: ['audience-insights-viewer'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED
      }
    },
    canActivate: [SessionValidationGuard, PermissionGuard, UnsavedAudienceDetectorGuard],
  },
  {
    path: MainNavigationEnum.CPI,
    loadChildren: () => import('./cpi-dashboard/cpi-dashboard.module').then(m => m.CampaignPerformanceInsightsModule),
    data:{
      permissions: {
        only: ['campaign-performance-insights-viewer'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED
      }
    },
    canActivate: [SessionValidationGuard, PermissionGuard, UnsavedAudienceDetectorGuard],
  },
  {
    path: MainNavigationEnum.CAMPAIGN_WORKFLOW,
    loadChildren: () => import('./campaign-workflow/campaign-workflow.module').then(m => m.CampaignWorkflowModule),
    data:{
      permissions: {
        only: ['campaign-viewer'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED
      }
    },
    canActivate: [SessionValidationGuard, PermissionGuard, UnsavedAudienceDetectorGuard],
  },
  {
    path: MainNavigationEnum.LOOKUP,
    loadChildren: () => import('./lookup/lookup.module').then(m => m.LookupModule),
    data: {
      permissions: {
        only: ['consumer-profile-viewer'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED,
      },
    },
    canActivate: [SessionValidationGuard, PermissionGuard, UnsavedAudienceDetectorGuard],
  },
  {
    path: MainNavigationEnum.APPLICATION_INTEGRATIONS,
    loadChildren: () => import('./application-integrations/application-integrations.module').then(m => m.ApplicationIntegrationsModule),
    data:{
      permissions: {
        only: ['app-integrations-admin', 'file-config-read', 'file-uploads-read'],
        redirectTo: MainNavigationEnum.UNAUTHORIZED
      }
    },
    canActivate: [SessionValidationGuard, PermissionGuard, UnsavedAudienceDetectorGuard],
  },
  // Added PLANNING_RESEARCH and CAMPAIGNS paths for backward compatibility to allow users access the bookmarked URLs with OLD module names.
  { path: `${MainNavigationEnum.PLANNING_RESEARCH}`, redirectTo: `${MainNavigationEnum.AUDIENCE_INSIGHTS}`, pathMatch: 'prefix' },
  { path: MainNavigationEnum.UNAUTHORIZED, component: UnauthorizedComponent, pathMatch: 'full',  canActivate: [ SessionValidationGuard ] },
  { path: '**', redirectTo: `/${MainNavigationEnum.LOGIN}` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
